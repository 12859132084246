<template>
  <base-section id="home-first">
    <v-container>
      <v-row>
        <v-col
          cols="12"
        >
          <div class="text-subtitle-1">
            Création
          </div>
          <p>
            Ironwood est une société indépendante fondée en 2019 par Titian Grandjean, constructeur
            métallique serrurier de formation.
          </p>
          <div class="text-subtitle-1">
            Commencement
          </div>
          <p>
            Tout d’abord, une passion :
          </p>
          <blockquote>
            Créer et concevoir des aménagements de véhicules.
          </blockquote>
          <p>
            Tant de possibilités de conceptions, de matériaux, de projets et la déception de ne pas pouvoir
            réaliser mes idées bouillonnantes, que pour y remédier, quoi de mieux que de proposer mes services
            à des personnes partageant la même passion.
          </p>
          <p>
            C’est de ce raisonnement qu’est née en mai 2019, IronWood, une entreprise qui met un point
            d’honneur à répondre à vos envies.
          </p>
          <p>
            En septembre 2019, l’envie de pratiquer également, le métier dans lequel j’ai évolué depuis mes 15
            ans, me pousse à étoffer les services que je propose avec de la construction métallique, une
            profession qui stimule également mon épanouissement et ma créativité.
          </p>
          <p>
            La réalisation de vos projets les plus fous, comme des plus téméraires, me met des défis, qu’il me
            tient à cœur de relever.
          </p>
          <p>
            Je reste à votre disposition pour tous renseignements complémentaires et vous remercie de l’intérêt
            que vous portez à mon entreprise.
          </p>
          <p class="signature">Titian Grandjean</p>

<!--          <div class="subtitle-1">-->
<!--            Effectif actuel-->
<!--          </div>-->
<!--          <p>-->
<!--            2 collaborateurs.-->
<!--          </p>-->
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionHomeSociety',

    data: () => ({
    }),
  }
</script>
<style lang="scss" scoped>
  blockquote {
    quotes: "“" "”" "‘" "’";
    font-weight: bold;
    display: block;
    margin: 30px 0;
    font-size: 16px;
    text-align: center;
    text-transform: capitalize;
    line-height: 12px;
    text-indent: -0.45em;
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    &::before {
      content: open-quote;
      text-align: left;
      //left: 0
      left: 0;
      top: 0;
    }
    &::after {
      text-align: right;
      content: close-quote;
      right: 0;
      top: -5px;
    }
    @media all and (max-width: 409px){
      width: 100%;
      padding: 0 15px;
    }
    &::after,&::before{
      font-size: 32px;
      display: block;
      position: absolute;
    }
  }
  .signature{
    margin-top: 25px;
    padding-top: 5px;
    border-top: 1px solid #212121;
    display: inline-block;
  }
</style>
